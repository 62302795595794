<i18n>
  {
    "es": {
      "CLOSE": "Cerrar"
    },
    "en": {
      "CLOSE": "Close"
    },
    "fr": {
      "CLOSE": "Fermer"
    },
    "de": {
      "CLOSE": "Schließen"
    },
    "it": {
      "CLOSE": "Chiudere"
    },
    "pt": {
      "CLOSE": "Fechar"
    }
  }
</i18n>

<template>
  <div
    class="bg-zinc-800 pointer-events-auto mt-4 w-full max-w-[25rem] overflow-hidden rounded-md shadow-lg ring-1 ring-black ring-opacity-5"
  >
    <div class="py-2 px-4">
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-3">
          <div class="flex-shrink-0">
            <div v-if="props.message.type === 'error'">
              <img
                src="@/assets/svg/icons/icons-error-snackbar.svg"
                alt="icono de error"
                class="h-6 w-6"
              />
            </div>
            <div v-if="props.message.type === 'success'">
              <img
                src="@/assets/svg/icons/icons-success-snackbar.svg"
                alt="icono de éxito"
                class="h-6 w-6"
              />
            </div>
            <div v-if="props.message.type === 'info'">
              <img
                src="@/assets/svg/icons/icons-info-snackbar.svg"
                alt="icono de información"
                class="h-6 w-6"
              />
            </div>
            <div v-if="props.message.type === 'warning'">
              <img
                src="@/assets/svg/icons/icons-warning-snackbar.svg"
                alt="icono de warning"
                class="h-6 w-6"
              />
            </div>
          </div>

          <p class="text-white font-semibold tracking-wide text-xs">
            {{ message.text }}
          </p>
        </div>

        <div class="ml-4 flex flex-shrink-0">
          <button
            @click="remove"
            class="inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
          >
            <span class="sr-only">{{ t('CLOSE') }}</span>
            <img
              src="@/assets/svg/icons/icons-close-snackbar.svg"
              alt="icono de cerrar"
              class="h-5 w-5"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  message: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n({
  useScope: 'local',
})

const snackBar = useSnackBar()

const remove = () => {
  snackBar.removeMessage(props.message.id)
}
</script>
