<template>
  <teleport to="body">
    <div
      id="snackbar"
      class="fixed bottom-0 z-9999 mb-4 flex w-full flex-col items-center justify-center gap-2 px-6"
    >
      <transition-group name="slide-fade">
        <SnackBarMessage
          v-for="message in messages"
          :key="message.id"
          :message="message"
        />
      </transition-group>
    </div>
  </teleport>
</template>

<script setup>
const messages = useSnackBarMessages()
</script>
